@keyframes loadingMessage {
  0%   {
    color: $text-tertiary-color;
  }
  50%  {
    color: mix($text-tertiary-color, white, 50%);
  }
  100% {
    color: $text-tertiary-color;
  }
}

.section-beer-finder {
  .stockists {
    position: relative;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba($sand, 0.8);
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0s linear 0.3s;
    transform: translateX(-100vw);

    .loading-overlay-image {
      background-image: url("../images/eye-blinking-transparent.gif");
      background-size: (212px / 3) (272px / 3);
      width: (212px / 3);
      height: (272px / 3);
      z-index: 10;
      margin-bottom: 10px;
    }

    .loading-overlay-message {
      font-family: $font-family-sans-serif;
      animation: loadingMessage 1s infinite;
    }
  }

  &.loading {
    .loading-overlay {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      transform: translateZ(0);
    }
  }
}

.beer-finder-header {
  @media ( min-width: $screen-md-min ) {
    display: flex;
  }

  h3 {
    margin-bottom: 15px;
  }

  .beer-finder-locator {
    align-self: flex-end;
    margin-bottom: 15px;

    @media ( min-width: $screen-md-min ) {
      margin-left: auto;
    }
  }

  .beer-finder-info {
    @media ( min-width: $screen-md-min ) {
      max-width: 50%;
    }

    p {
      // font-style: italic;
      font-size: 16px;
    }
  }
}

.beer-finder-search {
  margin-bottom: 25px;

  > .form-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;

    &:focus-within {
      border-color: $brand-primary;
    }
  }

  .beer-finder-geo {
    margin-top: 15px;

    @media ( max-width: $screen-sm-max ) {
      width: 100%;
    }

    @media ( min-width: $screen-md-min ) {
      margin-left: 30px;
      margin-top: 0;
    }
  }
}

.beer-finder-search-input {
  flex: 1 1 auto;
  border: none;
  box-shadow: none;
  margin-left: 12px;
  font-size: 24px;
  font-family: $font-family-sans-serif;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.beer-finder-geo.btn {
  padding-left: 15px !important;
  padding-right: 15px !important;

  .fa {
    margin-right: 4px;
  }
}

.beer-finder-filters {
  margin-bottom: 20px;
}

.stockist {
  font-size: 16px;
  font-family: $font-family-sans-serif;
  color: $text-tertiary-color;

  .title {
    font-size: 1.125em;
    font-family: $font-family-serif;
    color: $text-primary-color;
    margin-top: 0;
    // margin-bottom: 0.75rem;
    margin-bottom: 0;

    .fa {
      font-size: 0.6666666667em;
      opacity: 0.6;
    }

    a {
      color: $text-primary-color;
    }

    a:hover {
      .fa {
        opacity: 1;
      }
    }
  }

  .store-type-container {
    vertical-align: middle;
    margin-bottom: 0.75rem;

    .marker {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 5px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      border: 1px solid $text-tertiary-color;
      background-color: currentColor;
    }
  }

  .storetype {
    text-transform: uppercase;
    color: $text-tertiary-color;
    font-size: 12px;
    // font-weight: bold;
  }

  &:first-child {
    padding-top: 2rem;
  }

  &.infowindow {
    font-size: 14px;
    padding: 0;
    border: none;
    color: mix($text-primary-color, white, 85%);

    .title {
      font-weight: bold;
    }

    .address {
      margin-top: 4px;
      // color: $text-tertiary-color;

      a {
        margin-top: 10px;
        display: block;
        // text-transform: uppercase;
        font-weight: normal;

        .fa {
          font-size: 0.75em;
        }
      }
    }

    .terms {
      margin-top: 0.75rem;
      padding-top: 0.5rem;
      border-top: 1px solid $border-color;
    }

    .term-name {
      display: block;
      text-transform: uppercase;
      // font-weight: bold;
      font-size: 0.75em;
      color: $text-tertiary-color;
    }
  }

  .links {
    font-size: 16px;
    margin-top: 14px;

    a {
      display: inline-block;
      border-left: 1px solid $border-color;
      font-weight: normal;
      padding-left: 8px;
      margin-left: 8px;
      vertical-align: middle;
      color: mix($text-primary-color, white, 85%);

      &:first-child {
        border-left: none;
        padding-left: 0;
        margin-left: 0;
      }

      &:hover {
        color: $text-secondary-color;
      }
    }

    .fa {
      font-size: 12px;
    }
  }
}

.stockists-tab-nav {
  > li {
    width: 50%;
    text-align: center;
  }

  @media ( min-width: $screen-md-min ) {
    display: none !important;
  }
}

.stockists {
  // Stockists Wrapper
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.tab-content {
    .tab-pane {
      @media ( min-width: $screen-md-min ) {
        display: block !important;
      }
    }
  }
}

.stockists-list {
  position: relative;
  width: 100%;
  flex: 1 1 auto;

  @media ( min-width: $screen-md-min ) {
    width: 33.333333333%;
    overflow: hidden;
  }

  .list {
    padding: 0;

    @media ( min-width: $screen-md-min ) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: auto;
      margin: 0;
    }
  }

  .stockist {
    padding: 1.25rem 15px 1.5rem;
    border-bottom: 2px solid $border-color;

    &:hover {
      background-color: rgba($border-color, 0.4);
    }

    &:last-child {
      padding-bottom: 2rem;
      border-bottom: none;
    }
  }

  .no-results {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family-sans-serif;
    color: $dark-brown;
  }
}

.stockists-map {
  width: 100%;

  @media ( min-width: $screen-md-min ) {
    width: 66.666666667%;
  }
}
