// Call to Action
// ===============================

.section-contact,
.section-content {
  padding-bottom: 50px;
}

.section-tan-bg {
  background-color: $sand;
}
.section-white-bg {
  background-color: $white;
}
.section-brown-bg {
  background-color: $dark-brown;
}

.section-cta {
  background-color: $secondary-bg;
  padding-bottom: 190px;
  margin-bottom: -190px;
}

.cta-link {
  display: inline-block;
  font-weight: 700;
  font-family: $font-family-display;
  margin-bottom: 0.25em;
  .icon-direction-arrow-right {
    position: relative;
    //top: 5px;
    //line-height: 0;
    font-size: 150%;
    display: inline-block;
    margin-left: 10px;
    transition: transform 0.225s ease-in-out;
  }
  &:hover {
    .icon-direction-arrow-right {
      transform: translateX(10px);
    }
  }
}

.cta-image {
  img {
    @media (min-width: $screen-sm-min) {
      padding-right: 8.3333333333%;
      //padding-bottom: 30px;
    }
  }
}

.cta-content {

}

.button-next {
  .icon-direction-arrow-right {
    position: relative;
    //top: 5px;
    //line-height: 0;
    font-size: 150%;
    display: inline-block;
    margin-left: 10px;
    transition: transform 0.225s ease-in-out;
  }
  &:hover {
    .icon-direction-arrow-right {
      transform: translateX(10px);
    }
  }
}


// MAIN Footer Styles
// ===============================

.footer {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  font-size: 18px;
  color: $footer-color;
  background-color: $footer-bg;

  //margin-top: (190px * 0.5);
  @media (min-width: $screen-sm-min) {
    //margin-top: 190px;
  }
  /*
  &::before {
    content: "";
    position: absolute;
    top: (-190px * 0.5);
    width: 100%;
    height: (190px * 0.5);
    @include img-retina("../images/footer-transition.png","../images/footer-transition@2x.png",1434px,190px);
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: (1434px * 0.5) (190px * 0.5);
    z-index: 1;
    @media (min-width: $screen-sm-min) {
      top: -190px;
      height: 190px;
      background-size: 1434px 190px;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    not all, not all, not all, not all,
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx),
    only screen and (max-width: $screen-xs-max) {
      background-size: (1434px * 0.5) (190px * 0.5);
    }
  }
  */
  .nav-footer {
    @extend .list-unstyled;

    font-family: $font-family-display;
    font-weight: 700;
    li > a {
      color: $footer-color;
      &:hover,
      &:focus,
      &:active {
        color: $body-bg;
      }
    }
  }
}

.footer-transition-container {
  position: relative;
  width: 100%;
  height: 190px * 0.5;
  float: left;
  overflow: hidden;
  @media (min-width: $screen-sm-min) {
    height: 190px;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  not all, not all, not all, not all,  only screen and (min-resolution: 192dpi),  only screen and (min-resolution: 2dppx) {
    height: 190px * 0.5;
  }
}

@mixin footerTransition($width, $height) {
  width: 100%;
  height: $height * 0.5;
  background-size: ($width * 0.5) ($height * 0.5);
  @media (min-width: $screen-sm-min) {
    height: $height;
    background-size: $width $height;
  }
}

.footer-transition {
  position: absolute;
  bottom: -1px;
  background-position: center bottom;
  transition: transform ease-in-out 0.225s;
  transform: translateY(100%);
  &-back {
    @include footerTransition(1434px, 147px);
    @include img-retina("../images/transition-footer-back.png","../images/transition-footer-back.png",(1434px * 0.5),(147px * 0.5));
    background-repeat: repeat-x;
    width: 150%;
  }
  &-mid {
    @include footerTransition(1434px, 106px);
    @include img-retina("../images/transition-footer-mid.png","../images/transition-footer-mid.png",(1434px * 0.5),(106px * 0.5));
    background-repeat: repeat-x;
    width: 125%;
  }
  &-front {
    @include footerTransition(1434px, 79px);
    @include img-retina("../images/transition-footer-front.png","../images/transition-footer-front.png",(1434px * 0.5),(79px * 0.5));
    background-repeat: repeat-x;
    transform: translateY(100%);
    width:200%;
  }
  &-tree {
    @include footerTransition(1434px, 190px);
    @include img-retina("../images/transition-footer-tree.png","../images/transition-footer-tree.png",(1434px * 0.5),(190px * 0.5));
    background-repeat: no-repeat;
    transform: translateY(50%);
  }
  &-circle {
    @include footerTransition(1434px, 190px);
    @include img-retina("../images/transition-footer-circle.png","../images/transition-footer-circle.png",(1434px * 0.5),(190px * 0.5));
    background-repeat: no-repeat;
    transform: translateY(100%) scale(0.75);
  }
  &-base {
    @include footerTransition(1434px, 190px);
    @include img-retina("../images/transition-footer-base.png","../images/transition-footer-base.png",(1434px * 0.5),(190px * 0.5));
    background-repeat: repeat-x;
    transform: translateY(0);
  }
}

@keyframes footerSlideUp {
  to {
    transform: translateY(0);
    width: 100%;
  }
}

#footerTransition.animate .footer-transition:not(.footer-transition-base) {
  animation-duration: 1.75s;
  animation-iteration-count: 1;
  animation-name: footerSlideUp;
  animation-fill-mode: forwards;
}

.footer-navigation,
.footer-social {
  text-align: center;
  @media (min-width: $screen-sm-min) {
    text-align: left;
  }
}

.footer-copyright,
.footer-address {
  text-align: center;
  @media (min-width: $screen-sm-min) {
    text-align: right;
  }
}

.sub-footer {
  font-size: 14px;
  padding-top: 15px;
  @media (min-width: $screen-sm-min) {
    margin-top: 40px;
    border-top: 2px solid rgba($footer-color, 0.1);
  }
}

.footer-social .list-social a {
  display: inline-block;
}

.list-social {
  font-size: 18px;
  @media (max-width: $screen-xs-max) {
    padding-bottom: 10px;
  }
}

.crown-icon {
  margin: 0 auto 20px auto;
  @media (min-width: $screen-sm-min) {
    float: right;
    margin: 0 0 0 20px;
  }
}

.copyright {
  font-family: $font-family-sans-serif;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
	a{
		display: block;
		color: #fff;
		&:hover{
			color: $logo-bright-orange;
		}
		@media (min-width: $screen-sm) {
			display: inline-block;
		}
	}
	.bul{
		display: none;
		@media (min-width: $screen-sm) {
			display: inline-block;
		}
	}
}
