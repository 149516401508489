@font-face {
    font-family: 'icomoon';
    src:    url('#{$icomoon-font-path}/icomoon.eot?qgn6sq');
    src:    url('#{$icomoon-font-path}/icomoon.eot?qgn6sq#iefix') format('embedded-opentype'),
        url('#{$icomoon-font-path}/icomoon.ttf?qgn6sq') format('truetype'),
        url('#{$icomoon-font-path}/icomoon.woff?qgn6sq') format('woff'),
        url('#{$icomoon-font-path}/icomoon.svg?qgn6sq#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}
.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}
.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}
.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}
.icon-direction-arrow-right {
    position: relative;
    display: inline-block;
    //top: 0.225em;
    //line-height: 0.1em;
    vertical-align: middle;
    font-size: 150%;
    &:before {
        content: $icon-direction-arrow-right;
    }
}
.icon-direction-arrow-left {
    &:before {
        content: $icon-direction-arrow-left;
    }
}
.icon-untappd {
    font-size: 110%;
    line-height: 0.9;
    &:before {
        content: $icon-untappd;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}
.icon-instagram {
    &:before {
        content: $icon-instagram;
    }
}
.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}

