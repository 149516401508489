// Global Forms
label {
  font-size: 16px;
  font-family: $font-family-sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: $text-tertiary-color;
}

.form-control {
  box-shadow: none;
  border-width: 2px;
}

.custom-select {
  display: inline-block;
  width: 100%;
  line-height: 1.5;
  color: $text-tertiary-color;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 2px solid $border-color;
  border-radius: 0;
  appearance: none;
}

.custom-select:focus {
  border-color: $brand-primary;
  outline: 0;
  box-shadow: none;
}

// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

// Gravity Forms
// =================================

.gform_wrapper .top_label .gfield_label {
  @extend label;
}

body .gform_wrapper .top_label div.ginput_container {
  margin-top: 0;
}

.gform_wrapper .ginput_complex label,
.gform_wrapper .gfield_time_hour label,
.gform_wrapper .gfield_time_minute label,
.gform_wrapper .gfield_time_ampm label,
.gform_wrapper .gfield_date_month label,
.gform_wrapper .gfield_date_day label,
.gform_wrapper .gfield_date_year label,
.gform_wrapper .instruction,
.gform_wrapper .gfield_time_hour i,
.gform_wrapper div.charleft {
  font-weight: 100;
  font-size: 16px;
  color: darken($border-color, 25%);
}

.gform_wrapper .gfield_time_hour i {
  padding: 0.613em 0 0.413em 0;
}

.gform_wrapper .ginput_complex label,
.gform_wrapper .gfield_time_hour label,
.gform_wrapper .gfield_time_minute label,
.gform_wrapper .gfield_time_ampm label,
.gform_wrapper .gfield_date_month label,
.gform_wrapper .gfield_date_day label,
.gform_wrapper .gfield_date_year label {
  margin-bottom: 0;
}


.gform_wrapper input[type=text],
.gform_wrapper textarea,
.gform_wrapper input[type=url],
.gform_wrapper input[type=email],
.gform_wrapper input[type=tel],
.gform_wrapper input[type=number],
.gform_wrapper input[type=password],
.gform_wrapper select {
  background-color: transparent;
  padding: 0.613em 0 0.413em 0 !important;
  text-indent: .613em;
  border: 2px solid $border-color;
  &:focus {
    background-color: $white;
    outline: none;
    border-color: $brand-primary;
  }
}

.gform_wrapper select,
.gform_wrapper .gfield .ginput_container_time select {
  height: 52.5px;
  max-width: none !important;
  width: auto !important;
  min-width: 0 !important;
  text-indent: 0 !important;
}

.gform_wrapper .ginput_container_time {
  max-width: none;
  display: inline-block;
  min-width: 0;
  width: auto;

  input {
    text-indent: 0.3em !important;
  }
}

.gform_wrapper .gform_fileupload_multifile .gform_drop_area {
  border: 2px dashed $border-color;
  color: $text-primary-color;
}

.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_footer input[type=submit],
.btn-primary.btn-lg,
.gform_wrapper input.button.gform_button_select_files {
  width: 100%;
  padding: 10px 25px;
  background-color: $brand-primary;
  color: $white;
  font-weight: 700;
  border: 2px solid $brand-primary;
  //border-radius: 2px;
  transition: all 0.4s ease-in-out;
  @media (min-width: $screen-sm-min) {
    width: auto;
  }
  &:focus {
    outline: 0;
    background-color: transparent;
    color: $brand-primary;
    box-shadow: none;
  }
  &:hover,
  &:active {
    background-color: $brand-secondary;
    border: 2px solid $brand-secondary;
    box-shadow: none;
  }
}

.gform_wrapper .gfield_checkbox,
.gform_wrapper .gfield_radio {
  li {
    overflow: visible !important;
    display: inline-block;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
		position: relative;
		padding-left:40px;
		label {
  		display: inline-block;
  		margin-left: 27px;
  		padding-left: 8px;
			&:after {
				content:"";
				background: $border-color;
				width:27px;
				height:27px;
				display:block;
				position: absolute;
				left:0;
				top:0;
				border-radius:3px;
				z-index: 0;
			}
			&:before {
				content:"";
				display:block;
		    position: absolute;
		    width: 9px;
				height: 15px;
		    border: solid #fff;
		    border-width: 0 3px 3px 0;
	    	left: 9px;
				top: 3px;
				transform: rotate(45deg);
				opacity:0;
				z-index:1;
				transition: all 200ms ease-in-out;
			}
		}
    input[type=checkbox],
    input[type=radio] {
  		opacity:0;
  		outline:none;
  		z-index:100;
  		width:27px;
  		height:27px;
  		top:0;
  		left:0;
  		position: absolute;
  		appearance: none;
  		cursor: pointer;
      + label {
        cursor: pointer;
      }
      &:hover + label {
        &:before {
          opacity: 0.333333;
        }
      }
  		&:checked + label {
  			&:before {
  				opacity:1;
  			}
  		}
    }
  }
}

.gform_wrapper .gfield_radio {
  li {
		label {
			&:after {
  			border-radius: 50%;
			}
			&:before {
		    width: 9px;
				height: 9px;
				background-color: white;
				border-radius: 50%;
		    border: none;
		    border-width: 0;
	    	left: 9px;
				top: 9px;
				transform: none;
			}
		}
  }
}

.gform_wrapper ul.gfield_checkbox li input[type=checkbox],
.gform_wrapper ul.gfield_radio li input[type=radio] {

}

.gform_wrapper .ginput_complex .ginput_right {
  //margin-left: 1.3%;
}

li.gfield {
  margin-bottom: 10px;
  &.field_description_below {
    //margin-bottom: 0;
  }
}
