
$padding-sm:            15px;
$padding-md:            30px;
$padding-lg:            60px;

.row-flex {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: $screen-sm-min) {
    margin-left: -$padding-md;
    margin-right: -$padding-md;
  }
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

[class^=flex-col] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: $screen-sm-min) {
    padding-left: $padding-md;
    padding-right: $padding-md;
  }
}

.flex-col-half {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: $screen-md-min) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.flex-col-auto {
  @media (min-width: $screen-md-min) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
}

.no-gutter {
  padding: 0 !important;
}

// Pop Up Modal
.pop-up-modal {
  z-index: 2000;

  .close {
    @media (max-width: $screen-xs-max) {
      padding: 10px 12px !important;
      background-color: $white;
      opacity: 1;
    }
  }

  .img-container {
    min-height: 250px;
    height: 100%;
    min-width: 450px;
    max-width: 100%;
    background-size: 100%;
    background-size: cover;
    background-position: center center;

    > .hidden-image {
      opacity: 0;
    }
  }

}

.modal-centered {
  position: relative;
  margin: .5rem auto;
  pointer-events: none;
  display: flex;
  align-items: center;
  min-height: calc(100% - 16px);

  &.modal-lg {
    width: 940px;
    max-width: 100%;
  }

  @media (min-width: $screen-xs-min) {
    min-height: calc(100% - 60px);
  }

  .modal-content {
    display: flex;
    // height: 640px;
    width: 100%;
    pointer-events: all;
    border-radius: 0;
    border: none;

    > .row-flex {
      width: 100%;
      margin: 0;
      background-color: white;
    }
  }

  .close {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    padding: 18px;
  }
}

.pop-up-container {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $screen-sm-min) {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .heading-primary,
  .heading-secondary {
    margin: 0;
    color: $logo-orange;
    letter-spacing: 0.025em;
    font-family: $font-family-sans-serif;
  }

  .heading-secondary {
    font-size: 2.5rem;

    @media (min-width: $screen-sm-min) {
      font-size: 3rem;
    }
  }

  .heading-primary {
    line-height: 1;
    font-size: 3.5rem;
    text-transform: uppercase;

    @media (min-width: $screen-sm-min) {
      font-size: 4rem;
    }
  }

  .pop-up-content {
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    // font-size: 1.8rem;
    margin-top: 0;
    margin-bottom: 3rem;
    color: $dark-brown;
    color: $text-primary-color;
  }

  .btn-link {
    // color: $text-color;
    // font-size: 1.4rem;

    &:hover,
    &:focus {
      color: $brand-primary;
    }
  }
}

$icon-size: 34px;

.close-icon {
  display: inline-block;
  width: $icon-size;
  height: $icon-size;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjQiIHgxPSI5IiB4Mj0iNDEiIHkxPSIyNSIgeTI9IjI1Ii8+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS13aWR0aD0iNCIgeDE9IjI1IiB4Mj0iMjUiIHkxPSI5IiB5Mj0iNDEiLz48L3N2Zz4=);
  // background-color: red;
  background-repeat: no-repeat;
  background-size: $icon-size $icon-size;
  transform: rotate(45deg);
}
