// Global Styles
// ===============================

html {}

body {
  padding-right: 0px !important;
  &.admin-bar {
    .banner.sticky {
      @media (min-width: 783px) {
        top: 32px;
      }
    }
    #sectionVideo {
      @media (min-width: 783px) {
        top: 32px;
      }
    }
    #wpadminbar {
      transform: translate3d(0,0,0);
    }
  }
  &.menu-active {
    overflow: hidden;
    @media (min-width: $screen-sm-min) {
      overflow: auto;
    }
  }
}

.modal-open {
  overflow-y: auto;
}

main {
  //height: 100%;
}

section {
  //height:100%;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  @include clearfix();
  //transform-style: preserve-3d;
  .container {
    padding-top: 25px;
    padding-bottom: 25px;
    @media (min-width: $screen-sm-min) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
}

.bg-texture {
  background-color: rgba($border-color, 0.25);
  background-image: url('../images/grunge-sand.png');
  background-size: (1432px * 0.5) (1074px * 0.5);
}

.flex-center {
  display: flex;
  align-items: center;
}

.vertical-center {
  position: relative;
}

/*
.vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
}
*/

a {
  transition: color 0.225s ease-in-out;
  font-weight: 700;
}

.page-header {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 0;
  margin: 0 -15px;
  background-image: url("../images/page-header-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (min-width: $screen-sm-min) {
    padding-top: 80px;
    padding-bottom: 40px;
    h1 {
      margin-top: 40px;
      //margin-bottom: 40px;
    }
  }
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
  &-dark {
    background-color: rgba($deep-blue, 0.5);
  }
  &-light {
    background-color: rgba($white, 0.5);
  }
  &-orange {
    background-color: rgba($logo-orange, 0.5);
  }
  &-brown {
    background-color: rgba($dark-brown, 0.5);
  }
  &-brown-grad {
    //@include filter-gradient(#39342f, #39342f, horizontal);
    //@include background-image(linear-gradient(left, rgba(57,52,47,1) 0%,rgba(57,52,47,0.18) 50%,rgba(57,52,47,1) 100%));
    background: linear-gradient(to right, rgba(57,52,47,0.5) 0%,rgba(57,52,47,0.15) 50%,rgba(57,52,47,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#39342f', endColorstr='#39342f',GradientType=1 );
    &-dim {
      background: linear-gradient(to right, rgba(57,52,47,1) 0%,rgba(57,52,47,0.5) 50%,rgba(57,52,47,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#39342f', endColorstr='#39342f',GradientType=1 );
    }
  }
  &-slider {
    background-color: rgba($dark-brown, 0.7);
    &-grad {
      //@include filter-gradient(#39342f, #39342f, horizontal);
      //@include background-image(linear-gradient(left, rgba(57,52,47,1) 0%,rgba(57,52,47,0.18) 50%,rgba(57,52,47,1) 100%));
      background: linear-gradient(to right, rgba(57,52,47,0.7) 0%,rgba(57,52,47,0.15) 50%,rgba(57,52,47,0.7) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#39342f', endColorstr='#39342f',GradientType=1 );
    }
  }
  &-grunge {
    background-color: rgba($dark-brown, 0.5);
    background-image: url("../images/grunge-sand.png");
  }
}

// Flickity
.about-gallery {
  padding: 60px 0;
  @media (min-width: $screen-sm-min) {
    padding: 0;
  }
  &:after {
    content: '';
    display: none;
    @media (min-width: $screen-sm-min) {
      content: 'flickity';
    }
  }
  .gallery-cell {
    width: 100%;
    //height: calc(100vh - 80px);
  }
  &.flickity-enabled .gallery-cell {
    height: 100%;
  }
}

.timeline-gallery,
.about-gallery {
  width: 100%;
  height: 100%;
}

.timeline-gallery .gallery-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.timeline-nav {
  background: radial-gradient(ellipse at center, rgba($brand-secondary,1) 0%,rgba($brand-secondary,1) 50%,rgba($brand-secondary,0.5) 51%,rgba($brand-secondary,0) 52%,rgba($brand-secondary,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
  background-repeat: repeat-x;
  background-position: center center;
  background-size: 4px 4px;
  .gallery-cell {
    width: 12.5%;
    min-width: 200px;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    button {
      position: relative;
      font-family: $font-family-sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      background-color: $body-bg;
      transform: translate3d(0,0,0);
      &::after {
        font-family: 'icomoon' !important;
        content: $icon-chevron-down;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%) translateY(-20px);
        transition: all 0.25s ease-in-out;
        color: $brand-secondary;
        opacity: 0;
        //width: 20px;
        //height: 20px;
        line-height: 1;
        z-index: 1;
      }
    }
    &.is-nav-selected {
      button {
        color: $text-tertiary-color;
        &::after {
          color: $text-tertiary-color;
          transform: translateX(-50%) translateY(0);
          opacity: 1;
        }
      }
    }
  }
}

.timeline-thumb-container {
  display: inline-block;
  margin-bottom: 20px;
}

.flickity-prev-next-button {
  width: 30px;
  height: 100%;
  padding: 0;
  //position: absolute;
  //top: 50%;
  //width: 44px;
  //height: 44px;
  border: none;
  border-radius: 0;
  background: transparent;
  //cursor: pointer;
  //transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  @media (min-width: $screen-sm-min) {
    width: 90px;
  }
  &:hover,
  &:active {
    background: transparent;
    box-shadow: 0 0 0 0 $link-hover-color;
    opacity: 1;
    &:disabled {
      opacity: 0.25;
      .arrow {
        fill: $white;
      }
    }
    .arrow {
      fill: $link-hover-color;
    }
  }
  &:focus {
    box-shadow: 0 0 0 0 $link-color;
    .arrow {
      fill: $link-color;
    }
  }
  .arrow {
    fill: $white;
    transition: fill 0.2s ease-in-out;
  }
  svg {
    width: 20px;
    height: 20px;
    left: 5px;
    top: 50%;
    transform: translateY(-20px);
    @media (min-width: $screen-sm-min) {
      width: 30px;
      height: 30px;
      left: 30px;
      transform: translateY(-30px);
    }
  }
}

.flickity-page-dots {
  bottom: 55px;
  .dot {
    background: $light-brown;
  }
}

.button-next {
  background: transparent;
  border: none;
  padding: 0;
  @media (min-width: $screen-sm-min) {
    white-space: nowrap;
  }
  &:focus {}
}

.content-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 30px 15px 20px;
  z-index: 10;
  transform-style: preserve-3d;
  transform: translate3d(0,0,0);
  @media (min-width: $screen-sm-min) {
    padding: 0;
    width: 50%;
    text-align: center;
  }
  p {
    margin: 0 0 11px;
  }
  p, p.lead, h2 {
    color: $white;
  }
  h2 {
    text-align: center;
    margin: 0 auto 10px auto;
    @media (max-width: $screen-xs-max) {
      width: 80%;
    }
  }
}

.image-container {
  @media (min-width: $screen-sm-min) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
  }
  .gallery-cell-image {
    display: block;
    width: 100%;
    @media (min-width: $screen-sm-min) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%,-50%,0);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
      transform-style: preserve-3d;
      z-index: 1;
    }
  }
  .overlay {
    display: none;
    @media (min-width: $screen-sm-min) {
      display: block;
      transform-style: preserve-3d;
      transform: translate3d(0,0,0);
      z-index: 5;
    }
  }
}

.timeline-gallery {
  .content-container {
    @media (max-width: $screen-xs-max) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    .gallery-cell-image {
      display: block;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%,-50%,0);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
      transform-style: preserve-3d;
      z-index: 1;
    }
    .overlay {
      display: block;
      transform-style: preserve-3d;
      transform: translate3d(0,0,0);
      z-index: 5;
    }
  }
}

.gallery .gallery-cell-image.flickity-lazyloaded,
.gallery .gallery-cell-image.flickity-lazyerror {
  opacity: 1;
}


// Customised Tab Stuff
// ======================================

// Generic Tab stuff
.nav-tabs {
  border-bottom-width: 2px;
  > li {
    margin-bottom: -2px;
    > a {
      border-color: transparent;
      border-width: 0;
      border-top-width: 2px;
      border-left-width: 2px;
      border-right-width: 2px;
      &:hover,
      &:focus {
        border-color: transparent;
      }
    }
    &.active {
      > a,
      > a:focus,
      > a:hover {
        border-width: 2px;
        border-bottom: none;
      }
    }
  }

}

.sub-header span {
  display: none;
  &.active {
    display: block;
    opacity: 1;
  }
}

.beers-tab-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.left-tab-nav,
.right-tab-nav {
  position: fixed;
  top: 50%;
  z-index: 95;
  background-color: $text-tertiary-color;
  letter-spacing: 0.025em;
  transform: translateY(0);
  transition: transform ease-in-out 0.225s,
              opacity ease-in-out 0.325s;
  opacity: 1;
  //font-family: $font-family-sans-serif;
  //line-height: 20px;
  a {
    display: inline-block;
    color: white;
    padding: 25px 15px;
    &:hover {
      color: $brand-primary;
    }
  }
  &.active {
    opacity: 0.8;
  }
}

.left-tab-nav {
  left: 0;
  //transform: rotate(-90deg);
  transform: translate3d(0, -50%, 0);
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: vertical-lr;
  &.active {
    //transform: translateX(-100%);
    transform: translate3d(-100%, -50%, 0);
  }
  .beers-tab-nav.off-canvas & {
    transform: translate3d(-100%, -50%, 0);
  }
}

.right-tab-nav {
  right: 0;
  //transform: rotate(90deg);
  transform: translate3d(0, -50%, 0);
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: vertical-lr;
  &.active {
    //transform: translateX(100%);
    transform: translate3d(100%, -50%, 0);
  }
  .beers-tab-nav.off-canvas & {
    transform: translate3d(100%, -50%, 0);
  }
}

.tab-content {
  position: relative;
}

.fade {
  transition: opacity ease-in-out 0.325s,
}

@media (min-width: $screen-sm-min) {
  .tab-content>.tab-pane.fade {
    position: absolute;
    display: block;
    top: 0;
    left:0;
    right:0;
    //height: 0;
    //width: 0;
    pointer-events: none;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: transform ease-in-out 0.325s,
                opacity ease-in-out 0.325s,
                visibility linear 0.325s,
                height linear 0.325s,
                width linear 0.325s;
    &:first-child {
      transform: translateX(-50%);
    }
    &:last-child {
      transform: translateX(50%);
    }
  }

  .tab-content>.fade.active {
    position: relative;
    //height: auto;
    //width: auto;
    display: block;
    pointer-events: auto;
    transform: translateX(0) !important;
    opacity: 1;
    visibility: visible;
    overflow: visible;
  }
}

.btn-primary {
  background-image: url('../images/grunge-sand.png');
  background-size: (1432px * 0.5) (1074px * 0.5);
}

// Typography
// ===============================

body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (min-width: $screen-sm-min) {
    font-size: 18px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 20px;
  }
}

.page-header,
.section-lead {
  h1 {
    line-height: 1;
  }
}

h1 {
  font-family: $font-family-display;
  color: $text-primary-color;

  @media (min-width: $screen-sm-min) {
    font-size: 72px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 96px;
  }
  @media (min-width: $screen-lg-min) {
    font-size: 100px;
  }
}

h2 {
  font-family: $font-family-display;
  @media (min-width: $screen-sm-min) {
    font-size: 36px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 42px;
  }
  @media (min-width: $screen-lg-min) {
    font-size: 48px;
  }
}

h3 {
  font-family: $font-family-sans-serif;
}

h4 {
  font-family: $font-family-sans-serif;
}

body.page {
  h4 {
    font-family: $font-family-display;
    display: inline-block;
    color: #202830;
    font-size: 22px;
    font-weight: 700;
  }
}

h5 {}

h6 {}

p {
  margin-bottom: 15px;
}

.lead {
  font-family: $font-family-sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.1;

  color: $text-tertiary-color;
  @media (min-width: $screen-sm-min) {
    font-size: 32px;
  }
}

.sub-header {
  font-family: $font-family-sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.1;

  color: $text-secondary-color;
  @media (min-width: $screen-sm-min) {
    font-size: 32px;
  }
}

address {
  font-family: $font-family-sans-serif;
  strong {
    font-family: $font-family-display;
    font-size: 20px;
    line-height: 1.2857142861;
  }
}

.help-block {
  font-size: 15px;
  font-style: italic;
  margin-top: 6px;
  color: rgba($dark-brown, 0.7);
}

// Logos & Icons
// ===============================

.down-arrow-icon {
  display: inline-block;
  width: 34px;
  height: 34px;
  @include img-retina("../images/down-arrow.png","../images/down-arrow@2x.png",34px,34px);
  background-repeat: no-repeat;
}

.arrow-icon {
  display: inline-block;
  width: 32px;
  height: 16px;
  @include img-retina("../images/cta-arrow.png","../images/cta-arrow@2x.png",32px,16px);
  background-repeat: no-repeat;
  &.icon-small {
    width: 32px * 0.5;
    height: 16px * 0.5;
    background-size: (32px * 0.5) (16px * 0.5) !important;
  }
}

//400 × 230
.driftwood-logo-white {
  display: block;
  max-width: 400px;
  margin: 0 auto;
  @include img-retina("../images/driftwoodbrewery-logo-white.png","../images/driftwoodbrewery-logo-white@2x.png",400px,230px);
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;
}

.driftwood-logo {
  display: block;
  max-width: 250px;
  margin: 0 auto;
  @include img-retina("../images/driftwoodbrewery-logo.png","../images/driftwoodbrewery-logo@2x.png",250px,90px);
  background-size: (250px * 0.5) (90px * 0.5);
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;
}

.driftwood-full-logo {
  display: block;
  width: 400px;
  height: 219px;
  max-width: 50%;
  max-height: 219px * 0.5;
  margin: 0 auto;
  @include img-retina("../images/driftwood-full-logo.png","../images/driftwood-full-logo@2x.png",400px,219px);
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;
}


.driftwood-wordmark {
  display: block;
  max-width: 198px;
  margin: 0 auto;
  //height: 30px;
  @include img-retina("../images/driftwood-wordmark.png","../images/driftwood-wordmark@2x.png",198px,50px);
  background-size: 198px 50px;
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;
}



// Illustrated Transitions
// ===============================

@mixin waveTransition($color) {
  &-#{$color}-up,
  &-#{$color}-down {
    &::before,
    &::after {
      position: absolute;
      left: 0;
      height: 25px;
      width: 100%;
      pointer-events: none;
      transform: translate3d(0,0,0);

      background-repeat: repeat-x;
      background-size: (1434px * 0.5) (50px * 0.5);
      @media (min-width: $screen-lg-min) {
        height: 50px;
        background-size: auto;
      }
    }
  }
  &-#{$color}-up {
    &::before {
      content: "";
      top: -25px;
      background-image: url("../images/transition-#{$color}-up.png");
      z-index: 0;
      background-position: bottom center;
      @media (min-width: $screen-lg-min) {
        top: -50px;
      }
    }
  }
  &-#{$color}-down {
    &::after {
      content: "";
      bottom: -25px;
      background-image: url("../images/transition-#{$color}-down.png");
      z-index: 10;
      background-position: top center;
      @media (min-width: $screen-lg-min) {
        bottom: -50px;
      }
    }
  }
}

.transition {
  position: relative;
  @include waveTransition('tan');
  @include waveTransition('white');
  @include waveTransition('brown');
}

// Age Verification
// ===============================

.video-loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity .4s ease-in-out;
  opacity: 1;
  background-color: #38332e;
  z-index: 1050;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: (212px / 2);
    height: (272px / 2);
  }
  &.complete {
    opacity: 0;
    pointer-events: none;
  }
}

#age-please {
  //background-image: url('../images/default-banner.png');
  text-align: center;
  //background-color: #38332e;
  .modal-header img {
    margin-bottom: 40px;
    max-height: 180px;
  }
  .modal-dialog {
    height: 100%;
    margin: 0 auto;
    .modal-content {
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
      padding: 40px;
      border: none;
    }
  }
  &.in .modal-dialog {
  }
}

#ask-age {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.225s ease-in;
  &.conceal {
    opacity: 0;
    pointer-events: none;
  }
  .btn {
    font-weight: 700;
    font-size: 32px;
  }
  .no > .btn {
    //color: $dark-brown;
  }
}

.modal-content {
  box-shadow: 0 0 0 transparent;
}

#comeBackLater {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.225s ease-in;
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
}

.serving-icons {
  vertical-align: middle;
  .icon {
    // margin-right: 8px;
    vertical-align: bottom;
    display: inline-block;
    background-size: contain;
    &.icon-growler {
      width: 28px;
      height: 61px;
      background-image: url('../images/icon-growler-br@2x.png');
      background-size: contain;
      background-repeat: no-repeat;
      &.active {
        background-image: url('../images/icon-growler-lbr@2x.png');
      }
    }
    &.icon-bomber {
      width: 16px;
      height: 59px;
      background-image: url('../images/icon-bomber-br@2x.png');
      background-size: contain;
      background-repeat: no-repeat;
      &.active {
        background-image: url('../images/icon-bomber-lbr@2x.png');
      }
    }
    &.icon-short-can {
      width: 17px;
      height: 33px;
      background-image: url('../images/icon-shortcan-br@2x.png');
      background-size: contain;
      background-repeat: no-repeat;
      &.active {
        background-image: url('../images/icon-shortcan-lbr@2x.png');
      }
    }
    &.icon-tall-can {
      width: 17px;
      height: 46px;
      background-image: url('../images/icon-tallcan-br@2x.png');
      background-size: contain;
      background-repeat: no-repeat;
      &.active {
        background-image: url('../images/icon-tallcan-lbr@2x.png');
      }
    }
  }
}

// Flex Utilities

.row-flex {
  display: flex;
  flex-wrap: wrap;

  &::before,
  &::after {
    content: none;
  }
}
