// Navigation
// ===============================

// Layout
.nav-primary {
  @include make-row();
  .brand {
    @include make-sm-column(2);
  }
  .nav-left ,
  .nav-right {
    @include make-sm-column(5);
    li {
      display: block;
      float: left;
      width: 33.33333333333%;
    }
  }
}

// Sticky functionality
.banner-wrap {
  background-color: $header-bg;
  height: 50px;
  z-index: 100;
  @media (min-width: $screen-sm-min) {
    height: 80px;
  }
  .banner {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
  &.transition-white-down::after {
    z-index: -1;
    bottom: -15px;
    @media (min-width: $screen-lg-min) {
      bottom: -25px;
    }
  }
}

.scroll-prompt {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  opacity: 1;
  border-radius: 50%;
  border: 2px solid;
  height: 34px;
  width: 34px;
  text-align: center;
  background-color: rgba(white, 0);
  outline: none;
  padding: 0;
  line-height: 32px;
  font-size: 80%;
  //border: none;

  transition: opacity 0.2s ease-in-out 0.2s,
              color 0.2s ease-in-out;

  color: $link-color;
  @media (min-width: $screen-sm-min) {
    display: inline-block;
  }
  &:hover {
    color: $link-hover-color;
  }
  &:active,
  &:focus {
    color: $link-color;
  }
}

body.home {
  .brand {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out, transform 0s linear 0.2s;
  }

  .banner.sticky {
    .brand {
      opacity: 1;
      pointer-events: auto;
      transition: opacity 0.2s ease-in-out;
    }
    .scroll-prompt {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;
    }
  }
}

.banner.sticky {
  position: fixed;
}

// Styling
.banner {
  background-color: $header-bg;
}

.brand {
  display: block;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  //padding-top: 10px;
  //padding-bottom: 10px;
  height: 30px;
  text-align: center;
  color: transparent;

  @media (min-width: $screen-sm-min) {
    margin-top: 10px;
    margin-bottom: 20px;
    height: 50px;
  }
  .driftwood-full-logo {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .driftwood-logo {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transition: transform ease-out 0.225s 0.125s,
                opacity ease-out 0.225s 0.325s;

    opacity: 1;
    @media (min-width: $screen-sm-min) {
      width: 140px;
    }
  }
  .driftwood-wordmark {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: none;

    transition: transform ease-in-out 0.225s 0.125s,
                opacity ease-in 0.225s;

    opacity: 0;
    transform: translateY(15px);
  }
  &:hover {
    .driftwood-wordmark {
      opacity: 1;
      transform: translateY(0);
      transition: transform ease-in-out 0.225s 0.125s,
                  opacity ease-in 0.225s 0.125s;
    }
    .driftwood-logo {
      opacity: 0;
      transition: transform ease-in-out 0.225s,
                opacity ease-in 0.325s;
      //transform: translateY(-80px);
    }
  }
  @media (max-width: $screen-sm-max) {
    .driftwood-wordmark {
      opacity: 1;
      transform: translateY(0);
    }
    .driftwood-logo {
      opacity: 0;
      display: none;
    }
  }
}

.nav-right {
  text-align: center;
  @media (min-width: $screen-md-min) {
    text-align: left;
  }
}

.nav-left {
  text-align: center;
  @media (min-width: $screen-md-min) {
    text-align: right;
  }
}

.banner .container {
  @media (max-width: $screen-sm-max) {
    width: 100%;
  }
}

.nav-primary {
  font-size: 18px;
  font-weight: 700;
  font-family: $font-family-display;
  line-height: 1;
  @media (min-width: $screen-md-min) {
    font-size: 22px;
  }
  li {
    padding-top: 10px;
    padding-bottom: 10px;
    a {
      display: inline-block;
      padding: 21px 30px;
      color: $nav-link-color;
      transition: color ease-in-out 0.275s;
      @media (min-width: $screen-md-min) {
        padding: 19px 30px;
      }
      &::after {
        //content: "";
        position: absolute;
        height: 2px;
        left: 30px;
        bottom: 15px;
        right: 30px;

        transition: transform ease-in-out 0.125s,
                    opacity ease-in-out 0.125s,
                    background ease-in-out 0.275s;

        transform: translateY(5px);
        background-color: $nav-link-color;
        opacity: 0;
      }
      &:hover,
      &:focus,
      &:active {
        color: $link-color;
        background-color: transparent;
        &::after {
          transform: translateY(0);
          background-color: $link-color;
          opacity: 1;
        }
      }
    }
    &.active > a {
      color: $link-color;
      &::after {
        //transform: translateY(0);
        //background-color: $link-color;
        //opacity: 1;
      }
    }
  }
	.nav-right{
    .menu-socials a,
    .menu-icon-link {
				padding: 19px 4px;
				font-size: 14px;
				@media (min-width: 992px) {
					font-size: 16px;
				}
				@media (min-width: 1200px){
					font-size: 20px;
				}
			}
	}
}

.mobile-menu {
  z-index: 1050;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: $header-bg;
  opacity: 0;
  transform: translateY(-100%);
  transition: transform ease-in-out 0.125s,
              opacity ease-in-out 0.125s,
              background-color ease-in-out 0.275s;
  &.menu-open {
    //padding-top: 120px;
    padding: 50px 0;
    opacity: 1;
    transform: translateY(0);
    @media (min-width: $screen-sm-min) {
      transform: translateY(-100%);
    }
  }
  .brand {
    height: 90px;
    display: block;
    opacity: 1;
    margin-bottom: 30px;
    .driftwood-wordmark {
      opacity: 1;
    }
  }
  .nav {
    font-size: 36px;
    display: block;
    text-align: center;
    li {
      padding: 0;
      width: 100%;
    }
  }
}

.toggle-menu {
  z-index: 1050;
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px 15px;
  height: 0;
  overflow: hidden;
  text-indent: 10em;
  outline: 0 solid transparent;
  background-color: transparent;
  border: 0px solid transparent;
  color: $nav-link-color;
  @media (min-width: $screen-sm-min) {
    display: none;
    visibility: hidden;
  }
  span,
  span:before,
  span:after {
    width: 20px;
    height: 2px;
    background: $nav-link-color;
    @include transition(.2s);
  }
  &:hover {
    span,
    span:before,
    span:after {
      background: $link-color;
    }
  }
  span {
    display: block;
    position:relative;
    &:before {
      content:'';
      position:absolute;
      top: -6px;
      left: 0;
    }
    &:after {
      content:'';
      position:absolute;
      top: 6px;
      left: 0;
    }
  }
  &.menu-active span {
    background: transparent;
    &:before {
      transform: rotate(45deg);
      top:0;
    }
    &:after {
      transform: rotate(-45deg);
      top:0;
    }
  }
}

.mobile-menu-footer {
  padding: 40px 0;
  text-align: center;
}

.mobile-brand {
  display: block;
  padding-bottom: 30px;
}
