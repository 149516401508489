// EventsBar
#tribe-bar-form .tribe-bar-submit input[type=submit] {
  background-color: $tertiary-bg;
  border: 1px solid $tertiary-bg;
  font-family: $font-family-sans-serif;
  background-image: url('../images/grunge-sand.png');
  background-size: (1432px * 0.5) (1074px * 0.5);

  &:hover,
  &:focus {
    background-color: $text-tertiary-color;
  }
}

#tribe-events-bar {
  font-family: $font-family-sans-serif;
}

.tribe-bar-views-inner,
#tribe-bar-views .tribe-bar-views-list {
  background-color: white;
}

#tribe-bar-views .tribe-bar-views-option.tribe-bar-active,
#tribe-bar-views .tribe-bar-views-option:hover,
#tribe-bar-views-toggle:hover,
#tribe-bar-views-toggle:focus {
  background-color: $border-color;
}

#tribe-bar-collapse-toggle {
  background-color: white;
  background-image: url('../images/grunge-sand.png');
  background-size: (1432px * 0.5) (1074px * 0.5);
}

#tribe-bar-collapse-toggle:focus, #tribe-bar-collapse-toggle:hover {
  background-color: $text-tertiary-color;
}

// Footer
.single-tribe_events #tribe-events-footer, .tribe-events-day #tribe-events-footer, .events-list #tribe-events-footer, .tribe-events-map #tribe-events-footer, .tribe-events-photo #tribe-events-footer {
  border-top: 2px solid $border-color;
}

.tribe-events-sub-nav li a {
  @media ( max-width: 768px ) {
    background-color: white;
    transition: 0.2s ease-in-out;
    color: $text-color;

    &:hover {
      background-color: $border-color;
    }
  }
}

// Tickets
.tribe-events-event-cost {
  span {
    background-color: white;
    font-family: $font-family-sans-serif;
    background-image: url('../images/grunge-sand.png');
    background-size: (1432px * 0.5) (1074px * 0.5);
  }
}

// Event Details
.tribe-event-schedule-details,
.tribe-events-venue-details {
  font-family: $font-family-sans-serif;
  font-weight: normal;
}

// List View
.tribe-events-list-separator-month {
  span {
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    background-color: $body-bg;
  }

  &::after {
    transform: translateY(-2px);
    height: 4px;
    border: none;
    background: -webkit-radial-gradient(center,ellipse,#f4a434 0,#f4a434 50%,rgba(244,164,52,.5) 51%,rgba(244,164,52,0) 52%,rgba(244,164,52,0) 100%);
    background: -o-radial-gradient(center,ellipse,#f4a434 0,#f4a434 50%,rgba(244,164,52,.5) 51%,rgba(244,164,52,0) 52%,rgba(244,164,52,0) 100%);
    background: radial-gradient(ellipse at center,#f4a434 0,#f4a434 50%,rgba(244,164,52,.5) 51%,rgba(244,164,52,0) 52%,rgba(244,164,52,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000', GradientType=1 );
    background-repeat: repeat-x;
    background-position: center center;
    background-size: 4px 4px;
  }
}

.tribe-events-list .tribe-events-loop .type-tribe_events {
  padding-bottom: 20px;
  border-bottom: 2px solid $border-color;
  margin-bottom: 20px;

  &:last-child {
    border-bottom: 0;
  }

  .tribe-events-list-event-title {
    > a {
      color: $text-tertiary-color;

      &:hover,
      &:focus {
        color: $text-color;
      }
    }
  }

  .tribe-events-read-more {
    // @extend .btn;
    // @extend .btn-primary;

    margin-top: 8px;
    // font-size: 1.6rem;
    // color: $text-color;

    &:hover,
    &:focus {
      // color: $tertiary-bg;
    }
  }
}

.type-tribe_events ~ .tribe-events-list-separator-month {
  // margin-top: 22px;
  // padding-top: 26px;

  &::before {
    content: "";
    position: absolute;
    top: -32px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: $body-bg;
    pointer-events: none;
    z-index: 0;
  }
}

.tribe-events-day .tribe-events-day-time-slot .tribe-events-day-time-slot-heading {
  background-color: $border-color;
}

// Calendar View (Month)
.tribe-events-calendar thead th {
  background-color: $tertiary-bg;
  border-color: $tertiary-bg;
}

#tribe-events-content .tribe-events-calendar td {
  border-color: $border-color;
}

.tribe-events-calendar td.tribe-events-past div[id*='tribe-events-daynum-'],
.tribe-events-calendar td.tribe-events-past div[id*='tribe-events-daynum-'] > a {
  @media ( min-width: 769px ) {
    background-color: rgba($border-color, 0.5);
  }
}

.tribe-events-calendar td.tribe-events-present div[id*='tribe-events-daynum-'],
.tribe-events-calendar td.tribe-events-present div[id*='tribe-events-daynum-'] > a {
  @media ( min-width: 769px ) {
    background-color: $brand-primary;
  }
}

#tribe_events_filters_wrapper input[type=submit],
.tribe-events-button,
#tribe-events .tribe-events-button,
.tribe-events-button.tribe-inactive,
#tribe-events .tribe-events-button:hover,
.tribe-events-button:hover,
.tribe-events-button.tribe-active:hover {
  background-color: $brand-primary;
}

#tribe-events-content .tribe-events-calendar div[id*='tribe-events-event-'] h3.tribe-events-month-event-title {
  font-family: $font-family-sans-serif;
}

.tribe-events-tooltip {
  .entry-title {
    font-size: 1.75rem;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tribe-events-event-body {
    font-size: 12px;

    .tribe-event-duration {
      font-family: $font-family-sans-serif;
      margin-bottom: 5px;
    }
  }
}

.tribe-events-calendar td.mobile-active, .tribe-events-calendar .mobile-active div[id*='tribe-events-daynum-'], .tribe-events-calendar .mobile-active div[id*='tribe-events-daynum-'] a, #tribe-events-content .tribe-events-calendar .mobile-active:hover, #tribe-events-content .tribe-events-calendar td.tribe-events-othermonth.mobile-active, #tribe-events-content .tribe-events-calendar td.tribe-events-othermonth.mobile-active div[id*='tribe-events-daynum-'], #tribe-events-content .tribe-events-calendar td.tribe-events-othermonth.mobile-active div[id*='tribe-events-daynum-'] a,
.tribe-events-calendar td.tribe-events-present.mobile-active, #tribe-events-content .tribe-events-calendar td.tribe-events-present.mobile-active:hover, .tribe-events-calendar td.tribe-events-present.mobile-active div[id*='tribe-events-daynum-'], .tribe-events-calendar td.tribe-events-present.mobile-active div[id*='tribe-events-daynum-'] a {
  @media ( max-width: 768px ) {
    background-color: $text-tertiary-color;
  }
}

.tribe-events-calendar td.tribe-events-othermonth.tribe-events-future div[id*='tribe-events-daynum-'], .tribe-events-calendar td.tribe-events-othermonth.tribe-events-future div[id*='tribe-events-daynum-'] > a {
  @media ( max-width: 768px ) {
    background-color: transparent;
  }
}
