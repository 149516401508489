// Brewery (Front Page)
// ===============================

.tag-line {
  letter-spacing: -0.01em;
}

.mission-lead {
  color: $text-secondary-color;
  @media (min-width: $screen-md-min) {
    margin-bottom: 30px;
  }
  @media (max-width: $screen-xs-max) {
    text-align: left;
  }
}

.mission-statement {
  font-size: 20px;
  @media (max-width: $screen-xs-max) {
    text-align: left;
  }
}

.featured-image {
  padding-top: 20px;
  padding-bottom: 20px;
  @media (min-width: $screen-sm-min) {
    padding-top: 0;
    padding-bottom: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.section-lead {
  text-align: center;
  background-image: url("../images/lead-bg32pquant.png");

  background-repeat: no-repeat;
  //background-size: contain;
  background-size: (1000px * 0.5) (634px * 0.5);
  background-size: 200%;
  background-position: 50% 30%;
  @media (min-width: $screen-sm-min) {
    text-align: left;
    background-image: url("../images/page-header-bg.png");
    background-size: contain;
    background-position: top center;
  }
  .tag-line {
    @media (max-width: $screen-xs-max) {
      width: 222px;
      margin: 30px auto;
      font-size: 48px;
    }
  }
}

.section-presentation {
  background-color: $tertiary-bg;
  //padding-top: 50px;
  //padding-bottom: 50px;
}

.section-timeline {
  h2 {
    margin: 0;
    padding-top: 20px;
  }
  //padding-bottom: 50px;
}

.section-tours {
  //background-color: $dark-brown;
  padding-top: 60px;
  h2 {
    margin-top: 0;
  }
  p {
    margin-bottom: 22px;
  }
}

body.home {
  .banner-wrap {
    @media (min-width: $screen-sm-min) {
      margin-top: calc(100vh - 80px);
    }
  }
  &.admin-bar {
    .banner-wrap {
      @media (min-width: $screen-sm-min) {
        margin-top: calc(100vh - 80px - 32px);
      }
    }
  }
  &.no-video .banner-wrap {
    margin-top: 0;
  }
}


// Video Stuffs
#sectionVideo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: none;
  //position: relative;
  margin: 0;
  min-height: calc(100vh - 80px);
  background: #000;
  overflow: hidden;
  z-index: -1;
  @media (min-width: $screen-sm-min) {
    display: block;
  }
  body.admin-bar & {
    min-height: calc(100vh - 80px - 32px);
  }
  .video {
    //z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  video {
    position: absolute;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    overflow: hidden;
    @supports(object-fit: cover) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .video-element {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}


.logo-container {
  width: 400px;
  height: 230px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  z-index: 100;
  .driftwood-logo-white {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    transition: transform ease-out 0.225s 0.125s,
            opacity ease-out 0.225s 0.325s;
  }
  .video-cta {
    position: absolute;
    top: 50%;
    font-size: 48px;
    font-family: $font-family-display;
    line-height: 1.1;
    color: white;
    text-align: center;
    opacity: 0;
    transform: translateY(-45%);
    transition: transform ease-in-out 0.225s 0.125s,
                opacity ease-in 0.225s;
  }
  &a:hover {
    .video-cta {
      opacity: 1;
      transform: translateY(-50%);
      transition: transform ease-in-out 0.225s 0.125s,
                  opacity ease-in 0.225s 0.125s;
    }
    .driftwood-logo-white {
      opacity: 0;
      transition: transform ease-in-out 0.225s,
                opacity ease-in 0.325s;
    }
  }
}


// Beer Archive
// ===============================

.section-beer-archive {
  padding-bottom: 20px;
  overflow: hidden;
}

.beer-releases-nav {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 25px;
  background-color: $tertiary-bg;
  text-align: center;
  ul {
    position: relative;
    padding: 0;
    margin: 0;
    z-index: 15;
    li {
      @media (min-width: $screen-sm-min) {
        padding: 10px;
      }
      @media (min-width: $screen-lg-min) {
        padding: 15px;
      }
      a {
        position: relative;
        font-family: $font-family-sans-serif;
        font-weight: 700;
        color: $white;
        @media (min-width: $screen-sm-min) {
          padding: 10px;
        }
        @media (min-width: $screen-lg-min) {
          padding: 15px;
        }
        &:hover,
        &:active,
        &:focus {
          color: $link-color;
        }
        /*
        &::after {
          font-size: 16px;
          font-family: 'icomoon' !important;
          content: $icon-chevron-down;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) translateY(-20px);
          transition: all 0.25s ease-in-out;
          color: $white;
          opacity: 0;
          //width: 20px;
          //height: 20px;
          line-height: 1;
          z-index: 1;
        }
        */
      }
      &.active > a {
        color: $link-color;
        /*
        &::after {
          color: $link-color;
          transform: translateX(-50%) translateY(0);
          opacity: 1;
        }
        */
      }
    }
  }
  .transition::after {
    bottom: -25px;
  }
}

// figure.beer-container
img.lazy,
.image-loading {
  transition: opacity 225ms ease-in-out;
  opacity: 0;
}

.lazy.loaded,
.image-loaded {
  transition: opacity 225ms ease-in-out;
  opacity: 1;
}

.beer-container {
  text-align: center;
  margin-bottom: 15px;

  a {
    position: relative;
    display: block;
    transition: color 225ms ease-in-out, transform 225ms ease-in-out;
  }

  img {
    position: absolute;
    bottom: 90px;
    left: 0;
    right: 0;
    max-width: 33.3333%;
    margin: 15px auto;

    &.beer-thumb-landscape {
      max-width: 75%;
    }

    @media (min-width: $screen-sm-min) {
      //max-width: 50%;
      margin: 15px auto 30px auto;
    }
  }

  figcaption {
    padding-top: 143%;
  }

  &.related-beer {
    img {
      //max-width: 33.3333%;

      @media (min-width: $screen-sm-min) {
        max-width: 33.3333%;
      }
    }
    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
  }
  .beer-title {
    font-weight: 700;
    margin-bottom:  5px;
    white-space: nowrap;
  }
  .beer-availability {
    margin-bottom: 10px;
  }
  a:hover,
  a:focus,
  a:active {
    transform: translateY(-30px);
    .learn-more {
      opacity: 1;
      transform: translateY(0);

    }
  }
  .beer-info-title {
    display: none;
  }
  .serving-icons {
    display: inline-block;
    position: relative;
    .serving {
      display: inline-block;
      text-align: center;
      margin: 10px 8px 0 0;
      .measure {
        display: none;
      }
      .icon-growler {
        width: (28px/2);
        height: (61px/2);
      }
      .icon-bomber {
        width: (16px/2);
        height: (59px/2);
      }
      .icon-short-can {
        width: (17px/2);
        height: (33px/2);
      }
      .icon-tall-can {
        width: (17px/2);
        height: (46px/2);
      }
    }
  }
}

.related-beers-header {
  text-align: center;
  margin-top: 0;
  margin-bottom: 60px;
}

.beer-availability {
  color: $text-tertiary-color;
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.learn-more {
  color: $link-color;
  font-weight: 700;
  font-size: 18px;

  opacity: 0;
  transform: translateY(5px);
  transition: transform ease-in-out 0.125s,
              opacity ease-in-out 0.125s;
  .icon {
    display: inline-block;
    margin-left: 4px;
    font-size: 125%;
  }
}


// Single Beer Page
// ===============================

//Typography
.beer-title {
  color: $text-primary-color;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
}

.beer-style {
  margin-top: 0;
  line-height: 1;
}

.beer-style,
.beer-info-title{
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

// Layout & Styling
article.beer {
  //padding-top: 50px;
  //padding-bottom: 50px;
  &:last-child {
    //padding-bottom: 80px;
  }
  .section .container {
    @media (min-width: $screen-sm-min) {
      padding-bottom: 80px;
    }
  }
}

// Header
.beer-banner {
  @include make-row();

  position: relative;
  background-image: url('../images/default-banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 35%;

  .overlay {
    opacity: 0;
    pointer-events: none;
  }

  &.has-wordmark {
    padding-top: 0;

    .overlay {
      opacity: 1;
    }
  }

  &.has-image {
    &.hover {
      .overlay {
        opacity: 0;
      }
      .wordmark-column {
        opacity: 0;
      }
    }
    .overlay {
      @media (max-width: $screen-xs-max) {
        opacity: 0;
      }
    }
  }
}

.wordmark-container {
  position: relative;
  @extend .container;
  height: 0;
  padding-top: 35%;
  .row {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    transform: translate3d(0,0,0);
    .wordmark-column {
      position: absolute;
      top: 25px;
      left: 0;
      bottom: 35px;
      right: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      @media (max-width: $screen-xs-max) {
        display: none;
      }
      @media (min-width: $screen-sm-min) {
        top: 60px;
        left: auto;
        bottom: 90px;
        right: auto;
      }
      .image-wordmark {
        display: block;
        margin: 0 auto;
        //max-height: 100%;
        max-width: 75%;
        transform: translate3d(0,0,0);
      }
    }
  }
}

.wordmark-column {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

// Section Layout
.section-beer,
.section-beer-additional {
  padding-bottom: 25px;
  @media (min-width: $screen-sm-min) {
    padding-bottom: 35px;
  }
}

.section-beer-additional {
  background-color: $dark-brown;
  color: $white;
  .beer-info-title {
    color: $body-bg;
  }
}

.beer-description-container {
  position: relative;
}

.beer-bottle-container {
  margin: 0 auto;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  .beer-gallery {
    &::after {
      content: 'flickity';
      display: none;
    }

    @media (max-width: $screen-md-min) {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 2em;

      &::after {
        content: '';
      }
    }
  }
  .beer-image {
    padding: 0 3em;
    img {
      vertical-align: bottom;
      margin: 0 auto;
    }
    @media (max-width: $screen-md-min) {
      padding: 0 1em;
    }
  }
  .flickity-slider {
    display: flex;
    align-items: flex-end;
    @media (max-width: $screen-md-min) {
      align-items: center;
    }
  }
  .flickity-button {
    width: 50px;
    svg path {
      fill: #000;
    }
  }
  @media (min-width: $screen-sm-min) {
    position: absolute;
    bottom: 0;
    right:0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
// @keyframes moveFront {
//   0%   { z-index: 1; right: -20%; bottom: 20px; transform: scale(1);}
//   49% {z-index: 1; right: -40%; bottom: 10px; transform: scale(1); }
//   50% {z-index: 3; right: -40%; bottom: 10px; transform: scale(1);}
//   100% {z-index: 3; right: 20%; bottom: 0; transform: scale(1.05);}
// }
// @keyframes moveFrontBack {
//   0% {z-index: 3; right: 20%; bottom: 0; transform: scale(1.05); }
//   49% {z-index: 3; right:-40%; bottom: 10px;transform: scale(1.05); }
//   50% {z-index: 1; right: -40%; bottom: 10px; transform: scale(1.05);}
//   100%   { z-index: 1; right: -20%; bottom: 20px; transform: scale(1);}
// }
// @keyframes moveBack {
//   0%   { z-index: 2; left: -20%; transform: scale(1);}
//   49% {z-index: 2; left: -40%;transform: scale(1);}
//   50% {z-index: 1; left: -40%; transform: scale(1);}
//   100% {z-index: 1; left: 20%; transform: scale(0.95);}
// }
// @keyframes moveBackFront {
//   0% {z-index: 1; left: 20%;transform: scale(0.95); }
//   49% {z-index: 1; left: -40%;transform: scale(0.95); }
//   50% {z-index: 2; left: -40%;transform: scale(0.95);}
//   100%   { z-index: 2; left: -20%;transform: scale(1);}
// }

.beer-info {
  font-size: 20px;
  text-align: center;
}

.btn-beerfinder {
  margin-top: 30px;
}

.beer-description {
  padding-top: 30px;
  padding-bottom: 30px;
  //margin-top: 30px;
  //margin-bottom: 30px;
  text-align: left;
  .servings {
    margin-bottom: 1em;
    label {
      font-size: 0.75em;
      text-transform: uppercase;
      margin: 0.5em 0.5em 0 0;
      vertical-align: middle;
    }
  }

  img {
    @extend .img-responsive;
  }

  .tab-pane {
    @include clearfix();
  }

  .nav.nav-tabs {
    @extend h4;
    @extend .beer-info-title;

    margin-bottom: 30px;
    border: 0;
    text-align: center;

    li {
      display: inline-block;
      float: none;

      &:first-child {
        border-right: 2px solid #d4cfc9;
      }

      &:last-child {
        border-right: none;
      }

      a {
        border: 0;
        padding: 5px 15px;
      }
    }
  }
}

.beer-ingredients {
  @include make-row();
  //margin-top: 30px;
  //margin-bottom: 30px;
  padding: 40px 0;
  margin-bottom: 20px;
  font-size: 18px;

  @media (min-width: $screen-sm-min) {
    display: flex;
    background-size: 1432px 1074px;
  }

  .beer-ingredient {
    @include make-sm-column(4);
    position: relative;

    @media (min-width: $screen-sm-min) {
      &:first-child::after {
        content: "";
        position: absolute;
        right: 0;
        top: 15px;
        bottom: 15px;
        width: 2px;
        background-color: $border-color;
      }
      &:last-child::after {
        content: "";
        position: absolute;
        left: 0;
        top: 15px;
        bottom: 15px;
        width: 2px;
        background-color: $border-color;
      }
    }
  }
}

.beer-ingredient.abv {
  .value {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.1;
  }
  .super-percent {
    vertical-align: super;
    font-size: 50%;
    font-weight: 500;
  }
}

.beer-category {
  padding-top: 30px;
  padding-bottom: 10px;
  color: $text-tertiary-color;
  @media (min-width: $screen-sm-min) {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  p {
    font-size: $font-size-base;
    font-style: italic;
    color: $deep-blue;
  }
  .avail-title {
    color: $deep-blue;
    font-size: 1.5em;
    margin-top: 1em;
  }
  .servings {
    position: relative;
    .serving {
      display: inline-block;
      text-align: center;
      margin: 30px 15px 0 0;
      .measure {
        margin-top: 8px;
      }
    }
    &::after {
      content: '';
      display: block;
      border-right: 2px solid #d4cfc9;
      width: 2px;
      height: 3em;
      position: absolute;
      right: 0;
      top: 70px;
    }
    .measure {
      display: block;
      text-align: center;
      font-size: .6em;
    }
  }
}

.beer-meta {
  margin-bottom: 15px;
  font-size: 16px;
  @media (min-width: $screen-sm-min) {
    font-size: 18px;
  }
}

.beer-poetry {
  font-style: italic;
  > p {
    font-size: 22px;
    line-height: 34px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.beer-video {}

.beer-pairing {
  //margin-top: 30px;
  //margin-bottom: 20px;
  //font-size: 16px;
  //padding-top: 30px;
  //padding-bottom: 30px;
  text-align: left;
  .beer-info-title {
    text-align: center;
  }
  ul {
    margin-top: 7px;
    margin-bottom: 11px;
    padding-left: 0;
    //list-style: none;
    //margin-left: -5px;
    text-transform: capitalize;
    li {
      //display: inline;
      &:hover {
        //border-bottom: 2px solid $border-color;
      }
      &::after {
        //content: ".";
      }
    }
  }
}

.beer-social {
  text-align: center;
  margin-bottom: 15px;
}

.untappd {
  a {
    display: inline-block;
    .icon {
      display: inline-block;
    }
  }
  a {
    display: block;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 20px;
  }
  .untappd-icon {
    font-size: 60px;
    line-height: 1;
    margin-bottom: 2px;
  }
  .untappd-beer-name {
    display: none;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 1;
    margin-bottom: 4px;
  }
  .untappd-check-in {
    font-size: 16px;
  }
}


// Contact Page
// ===============================

.map-container {
  position: relative;
  margin: 20px 0;
  .map-link {
    //top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    //background-color: $dirty-sand;
    transition: opacity 0.4s ease-in-out;
    @media (min-width: $screen-sm-min) {
      position: absolute;
      opacity: 0;
    }
    span {
      display: block;
      width: 100%;
      padding: 20px;
      background-color: $white;
    }
  }
  a {
    &:hover {
      .map-link {
        opacity: 1;
      }
    }
  }
}

.growlers,
.contacts {
  margin-bottom: 40px;
}

.bordered-list {
  font-size: 18px;
  li {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid $border-color;
    &:first-child {
      border-top: 2px solid $border-color;
    }
    &.note {
      border-bottom: none;
      color: $text-tertiary-color;
      font-family: $font-family-sans-serif;
      //font-weight: 700;
      font-size: 18px;
      p {
        margin: 0;
      }
    }
    .value {
      font-family: $font-family-sans-serif;
      font-weight: 700;
      float: right;
    }
  }
}

.contacts h3 {
  margin-bottom: 20px;
}

.b1zn3zzC {
  margin-bottom: 20px;
  color: $text-tertiary-color;
  font-family: $font-family-sans-serif;
  //font-weight: 700;
  font-size: 18px;
  .name {
    display: inline-block;
    color: $text-primary-color;
    font-family: $font-family-display;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 1;
  }
  .title {
    display: inline-block;
    font-family: $font-family-serif;
    font-size: 20px;
    font-style: italic;
  }
}
